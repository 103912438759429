<template>
  <v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Seguro que quieres eliminarlo?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-btn
        @click="$router.push({ name: 'KBDrugs' })"
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs fixed-tabs v-model="activeTab" @change="loadData">
        <v-tab>Substrato de</v-tab>
        <v-tab>Inhibe</v-tab>
        <v-tab>Induce</v-tab>
        <v-tab>Relacionado con</v-tab>
      </v-tabs>

      <Autocompletion icon="mdi-pill" title="Genes" class="mt-10" />

      <v-data-table
        :headers="selectedHeaders"
        :items="rows"
        multi-sort
        :loading="loading"
        class="elevation-1 mt-5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import Autocompletion from "@/components/mylogy/Autocompletion.vue";
export default {
  props: ["drugId"],
  components: { Autocompletion },
  data() {
    return {
      loading: false,
      activeTab: 0,
      rows: [],
      editedItem: {},
      editedIndex: -1,
      dialogDelete: false,
      tableHeaders: {
        "kb-subs": [
          {
            text: "Gen",
            align: "start",
            sortable: true,
            value: "gene"
          }
        ],
        "kb-inhibitions": [
          {
            text: "Gen",
            align: "start",
            sortable: true,
            value: "gene"
          },
          {
            text: "Strength",
            align: "start",
            sortable: true,
            value: "strength"
          }
        ],
        "kb-inductions": [
          {
            text: "Gen",
            align: "start",
            sortable: true,
            value: "gene"
          }
        ],
        "kb-relationships": [
          {
            text: "Gen",
            align: "start",
            sortable: true,
            value: "gene"
          },
          {
            text: "Variants",
            align: "start",
            sortable: true,
            value: "variants"
          }
        ]
      }
    };
  },
  computed: {
    activeRelationshipType() {
      const values = [
        "kb-subs",
        "kb-inhibitions",
        "kb-inductions",
        "kb-relationships"
      ];
      return values[this.activeTab];
    },
    selectedHeaders() {
      return this.tableHeaders[this.activeRelationshipType].concat([
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ]);
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      let self = this;
      self.rows = [];
      self.loading = true;
      const res = await Strapi.get(
        `/${self.activeRelationshipType}?kb_drug_eq=${self.drugId}`
      );

      self.rows = res.data.map(r => {
        return {
          gene: r.kb_gene.symbol,
          gene_id: r.kb_gene.id,
          drug_id: r.kb_drug.id,
          id: r.id,
          strength: r.strength,
          variants: r.variants ? r.variants.map(v => v.variant).join(", ") : ""
        };
      });
      self.loading = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.rows.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      console.log(self.editedItem);
      self.closeDelete();
    }
  }
};
</script>
<style scoped></style>
